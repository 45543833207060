export default {
  extends: [
    'getClientInfo',
    'request',
    'pageLoaded', // 关闭客户端loading
    'login',
    'isLogin',
    'vipStatus',
    'payVip',
    'openWebView',
    'openURLScheme',
    'share',
    'rlog',
    'toast',
  ],
  events: [
    'vipStatusChange',
    'onPageVisibilityChange',
  ],
};
