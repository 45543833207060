<template>
  <router-view />
</template>

<script setup>
import { isMobile } from '@/utils';
import { useRouter } from 'vue-router';
import { onMounted, onUnmounted } from 'vue';

const $router = useRouter();
const routerHandle = () => {
  const params = window.location.hash?.split('?')?.[1]?.toString() || '';
  if (!isMobile()) {
    $router.push(`/web?${params}`);
  } else {
    $router.push(`/mobile?${params}`);
  }
};
onMounted(() => {
  window.addEventListener('hashchange', routerHandle());
});
onUnmounted(() => {
  window.removeEventListener('hashchange', routerHandle());
});
</script>
