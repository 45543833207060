/* eslint-disable */
const ua = navigator.userAgent.toLowerCase() || ''
const isIpad = /ipad/i.test(ua)
const isIpod = /ipod/i.test(ua)
const isIphone = /iphone/i.test(ua)
const isMugic = /neteasemusic/i.test(ua)
const isIos = isIphone || isIpad || isIpod
const isAndroid = /android/i.test(ua)
const uaWB = navigator.userAgent.toLowerCase().includes('weibo')
const isWx = navigator.userAgent.toLowerCase().includes('micromessenger')
const isAnother = isWx || uaWB || isMugic
export { isIos, isAndroid, isAnother }
// 蒙层显示跳转
export const openInBrowser = () => {
  const dialog = document.createElement('div')
  const mask = document.createElement('div')
  const img = document.createElement('img')
  dialog.style.cssText = 'position: fixed;top: 0;z-index: 99999;left: 0;right: 0;bottom: 0;'
  dialog.classList.add('open-dict-dialog')
  document.body.appendChild(dialog)
  mask.style.cssText = 'position: fixed;top: 0;z-index: 99999;left: 0;right: 0;bottom: 0;background: #040812;opacity: 0.8'
  mask.classList.add('open-dict-mask')
  dialog.appendChild(mask)
  img.src = 'https://oimagec7.ydstatic.com/image?id=1836528283477984378&product=xue'
  img.style.cssText = 'width: 235px;position: fixed;z-index: 999999;top: 0;right: 0;'
  img.classList.add('open-dict-guide-img')
  dialog.appendChild(img)
  const forbidScroll = (e) => {
    e.preventDefault()
  }
  // 移除
  const moveDialog = () => {
    mask.removeEventListener('click', moveDialog, false)
    mask.removeEventListener('touchmove', forbidScroll, false)
    dialog.remove()
  }
  mask.addEventListener('touchmove', forbidScroll, false)
  mask.addEventListener('click', moveDialog, false)
}
// 是否是手机环境
export const isMobile = () => {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
};
// 比较版本
function checkVersion(versionArr, arr) {
  for (let i = 0; i < arr.length; i++) {
    if (Number(versionArr[i]) > Number(arr[i])) return true
    if (Number(versionArr[i]) < Number(arr[i])) return false
  }
  return true
}
export const compareVersion = (version) => {
  const androidVersion = '9.3.00'.split('.')
  version.replace(/[a-zA-Z]/, '')
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  const versionArr = version.split('.')
  return checkVersion(versionArr, androidVersion)
}