import jsApiList from './jsApiList';

const { ydk } = window;

ydk.config({
  debug: false,
  jsApiList: [
    ...jsApiList.extends,
    ...jsApiList.events,
  ],
});

const extendObj = {};
const needMock = !true;
/* eslint-disable no-underscore-dangle */
jsApiList.extends.forEach((api) => {
  extendObj[api] = (params = {}, successCb, failCb) => {
    if (needMock && process.env.NODE_ENV !== 'production') {
      if (successCb) {
        successCb();
      }
      return;
    }
    const promiseParams = {
      ...params,
      success(res) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(api, ' success', res);
        }
        if (successCb) {
          successCb(res);
        } else if (params.success) {
          console.log('params.success', res);
          // params.success(res.data, res.responseCallback)
          if (res.data) {
            params.success(res.data, res.responseCallback);
          } else {
            params.success(res, res.responseCallback);
          }
        }
      },
      fail(res) {
        if (failCb) {
          failCb(res);
        } else if (params.fail) {
          params.fail(res);
        }
      },
    };
    ydk._invoke(api, promiseParams, promiseParams);
  };
});

jsApiList.events.forEach((api) => {
  extendObj[api] = (params = {}, successCb, failCb) => {
    if (needMock && process.env.NODE_ENV !== 'production') {
      window[api] = successCb;
    }
    const promiseParams = {
      ...params,
      success(res) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(api, ' success', res);
        }
        if (successCb) {
          successCb(res, res.responseCallback);
        } else if (params.success) {
          params.success(res, res.responseCallback);
        }
      },
      fail(res) {
        console.log('fail', res);
        if (failCb) {
          failCb(res);
        } else if (params.fail) {
          params.fail(res);
        }
      },
    };
    ydk._on(api, promiseParams, promiseParams);
  };
});

ydk.extend(extendObj);
