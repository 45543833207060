/* eslint-disable */
const imgPreloader = (url) => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = () => {
    resolve();
  };
  image.onerror = () => {
    reject();
  };
  image.src = url;
});
export const imgsPreloader = (imgs) => {
  const promiseArr = [];
  imgs.forEach((element) => {
    promiseArr.push(imgPreloader(element));
  });
  return Promise.allSettled(promiseArr);
};
