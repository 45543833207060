import { createApp } from 'vue';
import router from '@/router/route';
import { Swipe, SwipeItem, Toast } from 'vant';
import '@/assets/css/reset.css';
import 'vant/lib/index.css';
import '../public/rem';
import '@/bridge/ydkInit';
// 导入图片预加载方法以及图片列表
import { imgsPreloader } from '@/utils/imgPreloader';
import imgPreloaderList from '@/utils/imgPreloaderList';
import App from './App.vue';

(async () => {
  await imgsPreloader(imgPreloaderList);
  createApp(App).use(router).use(Swipe).use(SwipeItem)
    .use(Toast)
    .mount('#app');
})();
