/* eslint-disable */
import { createRouter, createWebHashHistory } from 'vue-router';
import { isMobile } from '@/utils';

const routes = [
  {
    path: '/',
    redirect: isMobile() ? '/mobile' : '/web',
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/web/WebPage'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('@/views/mobile/MobilePage'),
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '@/views/mobile/ProductPage'),
  },
  {
    path: '/productWeb',
    name: 'productWeb',
    component: () => import(/* webpackChunkName: "about" */ '@/views/web/ProductWebPage'),
  },
  {
    path: '/userStories/:name',
    name: 'userStories',
    component: () => import(/* webpackChunkName: "about" */ '@/views/web/UserStoriesWebPage'),
  },

];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
